/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
    display:inline;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "syntax";
@import "../assets/css/variables.scss";

.article-post {
    font-family: $bodyFont;
}

.mainheading h1.sitetitle {
    font-family: $headingFont;
}
.mainheading h1.posttitle {
//    font-weight: inherit;
}

h1, .h1 { font-size: 2rem; }
h2, .h2 { font-size: 1.5rem; }
h3, .h3 { font-size: 1.25rem; }
h4, .h4 { font-size: 1.125rem; }
h5, .h5 { font-size: 1.1125rem; }

h2.card-title, h2.card-title {
    font-weight: inherit;
}
.card-text, .card-text {
    font-weight: inherit;
    font-family: $bodyFont;
}


.article-post p img {
    display:block;
    margin:auto;
}

figcaption,
p > a img + strong,
p > img + strong {
    display:block;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    font-style: normal;
    font-weight: normal;
    font-family: $sansFont;
}

body, article, .article-post {
    .h1, h1 {
        font-family: $postTitleFont;
//        font-style: normal;
    }

    .h2, .h3, .h4, .h5, .h6,
    h2, h3, h4, h5, h6 {
        font-family: $headingFont;
//        font-weight: normal;
    }
    .h1, .h2, .h3,
    h1, h2, h3 {
//        font-style: italic;
    }

    h6, .h6 {
        font-family: $bodyFont !important;
        font-size: 1em;
        font-family: inherit;
        display: inline;
    }
}

h6 {
    display: inline;
}

h6 + p,
h6 + p {
    display: inline;
}

h6 + p + p,
.h6 + p + p {
    padding-top: 1rem;
}


.mainheading h1.sitetitle {
}

.featuredbox .wrapthumbnail a {
  height: 100% !important;
  display: block;
}

.featuredbox picture {
  height: 100% !important;
  display: block;
  overflow:hidden;
  background-size:cover;
}

.featuredbox picture img {
  height: 100% !important;
  object-fit:cover;
}

figure {
    margin: auto;
    width:100%;
}

figure img {
    display:block;
    margin:auto;
    max-width: 100%;
}

figure.breakout img {
    margin: inherit;
    max-width: 100vw;
}

iframe {
    margin:auto;
    width:100%;
    max-width: 100%;
}


figure {
    text-align:center;
    margin-bottom: 1rem;

    figcaption {
        display: block;
    }
    @media (min-width: 768px) {
        figure {
            margin:5px;
            display:inline-block;
        }
        figure:first-of-type {
            margin-left:0;
        }
        figure:last-of-type {
            margin-right:0;
        }
        &.thirds figure {
            width: calc(100%/3 - 10px);
        }
        &.halves figure {
            width: calc(100%/2 - 10px);
        }
        &.quarters figure {
            width: calc(100%/4 - 15px);
        }
    }
}

figure.breakout,
iframe.breakout,
.article-post img.breakout,
.breakout {
    width: 100vw;
    margin: 0 calc((100% - 100vw) / 2 - 30px);
    max-width: 100vw;
    position:relative;
}


body.explore_lyap {
    .site-content {
        padding-top: 0;
        z-index: 1;
    }
    .mainheading {
        padding-left: 16px;
        z-index: 2;
        color: white;
        position: absolute;
    }
}

blockquote {
    border-left:none;//4px solid rgba(0,0,0,0.5);
    padding:1rem 2rem;
    font-size:1.2em;
    color:rgba(0,0,0,0.5);
    font-style: italic;
}

.nibicon {
    color: rgba(0,0,0,0.44);
}

.nibicon svg, .nibicon img {
    width: 3rem;
    margin: 2rem;
}

.mugshot-left {
    float: left;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
}

.nsfw {
    color:grey;
    background-color:grey;
}

.nsfw:hover {
    background-color:inherit;
    color:inherit;
}
